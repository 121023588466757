<template>
	<div class="teacher">
		<list-template
        :loading="loading"
			:total="total"
			:table-data="tableData"
			:table-config="tableConfig"
			:current-page="page"
			@onChangePage="changeCurrentPage"
			@onHandle="tableHandle"
			hasAdd
			@onAdd="onAdd"
		></list-template>
	</div>
</template>

<script>
import {mapState} from 'vuex'

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
			return {
        loading:true,
				// 表格配置
				tableConfig: [
					{
						prop: 'subject',
						label: '辅导科目'
					},
					{
						prop: 'building',
						label: '楼宇 '
					},
					{
						prop: 'storey',
						label: '楼层'
					},
					{
						prop: 'addr_name',
						label: '辅导地点名称'
					},
					{
						prop: 'school',
						label: '校区'
					},
					{
						prop: 'creator',
						label: '创建人'
					},
					{
						label: '操作',
						handle: true,
						width: 120,
						render: row => {
							if (row.creator_id == this.$store.getters.userInfo.userinfo.id) {
								return ['编辑', '删除']
							}
							return ['查看']
						}
					}
				],
				search: null,
				total: 0,
				dialogVisible: false,
				// 表格中的数据
				tableData: []
			}
		},
		computed: {
			...mapState(['userInfo']),
			...mapState(['page'])
		},
		mounted() {
			this.$store.commit('setPage', 1)
			this.getData()
		},
		activated() {
			this.getData()
		},
		methods: {
			// 分页被更改
			changeCurrentPage(e) {
				this.$store.commit('setPage', e)
				this.getData()
			},
			/**
			 * 获取数据
			 */
			getData() {
				let { search, page } = this;
        this.loading = true;
				this.$_axios2.get('api/1v1/place', { params: { ...search, page } }).then(res => {
					this.tableData = res.data.data.data
					this.total = res.data.data.total
				}).finally(()=>this.loading = false);
			},
			// 新增按钮
			onAdd() {
				this.$router.push('./add')
			},
			// 表格后面操作被点击
			tableHandle(row, handle, index) {
        if (handle === 'delete') {
					this.$confirm('删除此房间?', {
						confirmButtonText: '删除',
						cancelButtonText: '我点错了',
						type: 'warning'
					}).then(() => {
						this.$_axios2.delete('api/1v1/place/' + row.id).then(res => {
							if (res.data.status === 104) {
								this.$alert('此地点已经被占用不可删除', {
									confirmButtonText: '我知道了',
									center: true
								})
							} else {
								if (this.tableData.length == 1) {
									this.$store.commit('setPage', this.page - 1)
									this.$message.success('删除成功')
									setTimeout(() => {
										this.getData()
									}, 20)
								} else {
									this.$message.success('删除成功')
									this.getData()
								}
							}
						})
					})
				}
        if (handle.type === "edit") {
					this.$router.push({ path: './edit', query: { id: row.id, type: '1' } })
				}
        if (handle.type === 'view') {
					this.$router.push({ path: './edit', query: { id: row.id, type: '0' } })
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.list {
		padding: 24rem 0 0 32rem;
		.search {
			.el-input,
			.el-select {
				width: 240rem;
				margin-right: 24rem;
			}
		}
		.pagination {
			margin-top: 50rem;
		}
	}
</style>
